<template>
    <b-card>
        <div>
            <b-modal v-model="modalShow" title="Modification raison sociale et prix d'abonnement" id="modal-prevent-closing" ref="modal" @ok="handleOk" @change="cleanVar">
                <form ref="form" @submit.stop.prevent="handleSubmit">
                    <b-form-group label="Raison sociale" label-for="corporateName-input" invalid-feedback="La raison sociale est requise" :state="corporateNameState">
                        <b-form-input required label="Raison sociale" id="corporateName-input" v-model="corporateName"> </b-form-input>
                    </b-form-group>

                    <b-form-group label="Nom point de vente" label-for="pointText-input" invalid-feedback="Le nom du point de vente est requis" :state="pointTextState">
                        <b-form-input required label="Nom point de vente" id="pointText-input" v-model="pointText"> </b-form-input>
                    </b-form-group>

                    <b-form-group v-if="!subByPass" label-for="subPrice-input" invalid-feedback="Le prix de l'abonnement est requis" :state="subPriceState">
                        <slot name="label"
                            >Prix de l'abonnement (TTC) <b-badge :variant="subStatusVariant" style="">{{ subStatusLabel }}</b-badge></slot
                        >

                        <b-form-input required id="subPrice-input" v-model="subPrice" style="display: flex"></b-form-input>

                        <div style="display: flex; align-items: baseline; margin: 5px 0px">
                            <b-button :variant="subBtnVariant" @click="handleChangeSubStatus">{{ subButton }}</b-button>
                            <p style="margin-left: auto">Prochain paiement : {{ subPayDay }}</p>
                        </div>
                    </b-form-group>

                    <b-form-group label="Email" label-for="email-input" invalid-feedback="L'email est requis" :state="emailState">
                        <b-form-input required id="email-input" v-model="email"></b-form-input>
                    </b-form-group>

                    <b-form-group label="Nom" label-for="name-input" invalid-feedback="Le nom est requis" :state="nameState">
                        <b-form-input required id="name-input" v-model="name"></b-form-input>
                    </b-form-group>

                    <b-form-group label="Adresse" label-for="adress-input" invalid-feedback="L'adresse est requis" :state="adressState">
                        <b-form-input required id="adress-input" v-model="address"></b-form-input>
                    </b-form-group>
                </form>
            </b-modal>
        </div>

        <b-input-group class="mb-1 w-25 d-flex ml-auto">
            <b-input-group-prepend>
                <b-input-group-text>
                    <feather-icon icon="SearchIcon"></feather-icon>
                </b-input-group-text>
            </b-input-group-prepend>
            <b-input v-model="filter"></b-input>
        </b-input-group>

        <b-table
            hover
            striped
            responsive
            ref="table"
            :filter="filter"
            :items="codeForTable"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            @row-clicked="(item) => modifyNameAndPrice(item)"
            @filtered="onTableFiltered"
        >
            <template #cell(price)="posInfo">
                <b class="text-info">{{ posInfo.item.details.subscription ? posInfo.item.details.subscription.amount / 100 : ' - ' }} €</b>
            </template>
        </b-table>
        <b-pagination class="mt-3" v-model="currentPage" :total-rows="rows" :per-page="perPage" first-number></b-pagination>
    </b-card>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    const moment = require('moment');

    export default {
        props: {
            posInfo: {
                type: Array,
                required: true,
            },
            subByPass: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                posIdx: 0,
                selectedPos: {},
                corporateNameState: null,
                pointTextState: null,
                subPriceState: null,
                subPrice: '',
                subStatus: '',
                subStatusLabel: '',
                subStatusVariant: '',
                subButton: '',
                subBtnVariant: '',
                subPayDay: '',
                corporateName: '',
                pointText: '',
                email: '',
                emailState: null,
                name: '',
                nameState: null,
                address: '',
                adressState: null,
                modalShow: false,
                fields: [
                    { key: 'point_code', label: 'Code point de vente' },
                    { key: 'details.company_name', label: 'Nom du point de vente' },
                    { key: 'price', label: "Prix de l'abonnement" },
                ],
                perPage: 10,
                currentPage: 1,
                filter: '',
                rows: 0,
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            codeForTable() {
                let data = this.posInfo;

                if (this.filter === '') this.setRows(data);
                return data;
            },
            formatSubscriptionPrice(amount) {
                const formatedAmount = (amount / 100).toString() + '€';
                return formatedAmount;
            },
        },
        watch: {},
        methods: {
            ...mapActions({
                updateNameAndSubPrice: 'app/updateNameAndSubPrice',
            }),
            setRows(list) {
                this.rows = list.length;
            },
            onTableFiltered(list) {
                this.setRows(list);
            },
            checkFormValidity() {
                // PROBLEM HERE
                const valid = this.$refs.form.checkValidity();
                this.subPriceState = valid;
                this.corporateNameState = valid;
                this.pointTextState = valid;
                this.emailState = valid;
                this.nameState = valid;
                return valid;
            },

            cleanVar() {
                this.subPayDay = '';
                this.subStatus = '';
                this.subPrice = '';
            },

            async modifyNameAndPrice(item) {
                if (!this.subByPass) {
                    if (this.subPrice || item.details.subscription) {
                        this.subPrice = this.subPrice || item.details.subscription.amount / 100;
                    } else {
                        this.subPrice = 0;
                    }
                }

                this.selectedPos = item;
                this.corporateName = item.details.company_name ? item.details.company_name : '';
                this.pointText = item.details.point_text ? item.details.point_text : '';
                this.address = item.details.address ? item.details.address : '';
                this.name = item.details.name ? item.details.name : '';
                this.email = item.details.email ? item.details.email : '';
                if (!this.subByPass) this.manageSubStatus(this.subStatus || item.details.subscription.status);
                this.subPayDay = !this.subPayDay && !this.subByPass ? this.formatPayDay(item.details.subscription.payDay) : this.formatPayDay(this.subPayDay) || 'unknown';

                this.modalShow = !this.modalShow;
            },

            formatPayDay(day) {
                const m = moment().month() + 1;
                const y = moment().year();
                const d = day;
                const s = d + '-' + m + '-' + y;
                const date = moment(s, 'DD-M-YYYYY').format('DD-MM-YYYY');

                return date;
            },

            handleOk(bvModalEvent) {
                // Prevent modal from closing
                bvModalEvent.preventDefault();
                // Trigger submit handler
                this.handleSubmit();
            },

            handleChangeSubStatus() {
                if (this.subStatus == 'active') this.manageSubStatus('suspended');
                else this.manageSubStatus('active');
            },

            manageSubStatus(subStatus) {
                this.subStatus = subStatus;
                this.subStatusLabel = subStatus == 'active' ? 'Actif' : 'Suspendu';
                this.subStatusVariant = subStatus == 'active' ? 'success' : 'danger';
                this.subButton = subStatus == 'active' ? 'Suspendre' : 'Activer';
                this.subBtnVariant = subStatus == 'active' ? 'danger' : 'success';
            },

            async handleSubmit() {
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                    return;
                }

                try {
                    //diff entre campany name et price avant req
                    const updateData = {};

                    if (this.selectedPos.details.name != this.name) updateData.name = this.name;

                    if (this.selectedPos.details.email != this.email) updateData.email = this.email;

                    if (this.selectedPos.details.company_name != this.corporateName) updateData.companyName = this.corporateName;

                    if (this.selectedPos.details.point_text != this.pointText) updateData.pointText = this.pointText;

                    if (this.selectedPos.details.address != this.address) updateData.address = this.address;

                    if (!this.subByPass && (!this.selectedPos.details.subscription || this.selectedPos.details.subscription.amount != this.subPrice * 100)) updateData.price = this.subPrice * 100;

                    if (this.selectedPos.details.subscription && this.subStatus && this.selectedPos.details.subscription.status != this.subStatus) updateData.subStatus = this.subStatus;

                    // To deploy subpayday modification
                    // if (this.selectedPos.details.subscription.payDay != moment(this.subPayDay, 'DD-MM-YYYY').date()) {
                    //   console.log(this.subPayDay);
                    //   console.log(moment(this.subPayDay, 'DD-MM-YYYY').date());
                    //     updateData.payDay = moment(this.subPayDay, 'DD-MM-YYYY').date();
                    //   }

                    const formatedUpdateData = {
                        brand: this.selectedPos.brand,
                        point_code: this.selectedPos.point_code,
                        data: updateData,
                    };

                    if (Object.keys(formatedUpdateData.data).length > 0) {
                        this.updateNameAndSubPrice(formatedUpdateData);
                        this.$toast(this.successToast('Données mises à jour avec succès'));
                        this.$emit('on-update-name-brand', formatedUpdateData);
                    }

                    // Hide the modal manually
                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-prevent-closing');
                    });
                } catch (error) {
                    console.log(error);
                    this.$toast(this.successToast('Erreur lors de la mise à jour des données'));
                    throw error;
                }
            },
        },
    };
</script>

<style></style>
