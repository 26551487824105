<template>
    <div>
        <b-card title="Gestion des points des ventes">
            <b-col cols="3">
                <b-form-group label="Sélectionner une marque" label-for="input-select-brand">
                    <b-form-select id="input-select-brand" v-model="selectedBrand" :options="brands" @change="fetchBrands"></b-form-select>
                </b-form-group>
            </b-col>

            <pos-table :posInfo="posInfo" :subByPass="subByPass" @on-update-name-brand="updateNamePrice"></pos-table>
        </b-card>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import PosTable from './pos-table.vue';

    export default {
        components: {
            PosTable,
        },
        data() {
            return {
                brands: [],
                posInfo: [],
                selectedBrand: 'citroen',
                subByPass: false,
            };
        },
        mounted() {
            this.init();
        },
        computed: {},
        methods: {
            ...mapActions({
                getBrands: 'app/getBrands',
                getPosInfoByBrand: 'app/getPosInfoByBrand',
                getConfigParams: 'app/getConfigParams',
            }),
            init() {
                this.$root.toggleGlobalLoading(false);
                let promises = [this.fetchBrands()];
                Promise.all(promises)
                    .then((res) => {
                        this.$root.toggleGlobalLoading(false);
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la récupération des données'));
                    });
            },
            fetchBrands() {
                return this.getBrands().then((res) => {
                    const brands = res.data.map((b) => b.name);
                    this.$set(this, 'brands', brands);
                    this.fetchPosInfo();
                });
            },
            async fetchPosInfo() {
                const subByPassRaw = await this.getConfigParams({
                    brand: this.selectedBrand,
                    field: 'byPassPayment',
                });

                let subByPass = subByPassRaw.data;
                if (subByPass == '') subByPass = false;

                this.$set(this, 'subByPass', subByPass);

                return this.getPosInfoByBrand(this.selectedBrand).then((res) => {
                    this.$set(this, 'posInfo', res.data);
                });
            },
            updateNamePrice(rawData) {
                const data = rawData.data;
                const pointCode = rawData.point_code;

                const posToUpdate = this.posInfo.find((pos) => pos.point_code == pointCode);
                if (posToUpdate) {
                    if (data.companyName) {
                        posToUpdate.details.company_name = data.companyName;
                    }
                    if (data.pointText) {
                        posToUpdate.details.point_text = data.pointText;
                    }
                    if (data.name) posToUpdate.details.name = data.name;
                    if (data.email) posToUpdate.details.email = data.email;
                    if (data.address) posToUpdate.details.address = data.address;
                    if (data.price || data.price == 0) posToUpdate.details.subscription = { amount: data.price };
                }
            },
        },
    };
</script>

<style lang="scss"></style>
